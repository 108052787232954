<template>
  <div class="player content-file-player">

    <div v-if="onlyRunsOnMobile">
      {{ $t('FileCanOnlyRunOnMobile') }}
    </div>
    <div v-else class="w-100 h-100">
      <div v-if="showInPlayer" class="content-file-player__wrapper">
        <pdf-player
          v-if="contentFileIsPDF"
          :src="frameUrl"
          :engMode="engineMode"
          :initialScrollMode="contentFileIsConvertedPpt ? 'Horizontal' : 'Vertical'"
          :initialScale="contentFileIsConvertedPpt ? 'pageWidth' : 'pageFit'"
          :sessionIdentifier="sessionIdentifier"
          @pageViewed="handlePageViewed"
        />

        <AAImage v-if="contentFileIsImage" :image-url="frameUrl" />

        <iframe v-if="!contentFileIsPDF && !contentFileIsImage" :src="frameUrl" class="player__frame" ref="playerFrame" @load="onIframeLoad" />
      </div>
      <div v-else class="content-file-player__button-wrapper">
        <b-button variant="primary" @click="downloadFile"
                  v-html="$t('DownloadFile')"></b-button>
      </div>
    </div>

    <div class="player__back" @click="onBackButtonClick">
      <div class="player__back__background"></div>
      <i class="fas fa-chevron-left"></i>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import ContentListModel from "@/content/_model/ContentListModel";
import {EngineMessageType, EngineMode} from "@/presentation/player/_model/player.constants";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import {IEngineMessageDto} from "@/presentation/player/_model/player.dto";
import audienceController from "@/audience/_controller/AudienceController";
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import {IAudienceSessionEventDto} from "@/audience/session/_model/audience_session.dto";
import contentController from "@/content/_controller/ContentController";
import {RoutingIdentifier} from "@/router";
import fileManager from "@/_controller/FileManager";
import {FILE_TYPE_CONFIG, FileType, IFileTypeConfig} from '@/asset_folder/_model/asset_folder.constants';
import {SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import EntityModel from "@/entity/_model/EntityModel";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";
import PlaylistListModel from "@/playlist/_model/PlaylistListModel";
import PdfPlayer from '@/_view/components/PdfPlayer.vue';
import AAImage from "@/_view/components/AAImage.vue";

@Component({
  components: {AAImage, PdfPlayer}
})
export default class ContentFilePlayer extends Vue {

    @Prop() public readonly engineMode!: EngineMode;
    @Prop() private readonly contentFileID!: string;
    @Prop() public readonly sessionIdentifier!: string;
    @Prop() private readonly inPlaylistMode?: boolean;

    private parentSessionIdentifier?: string;

    private fileType!: FileType;
    private fileTypeConfig!: IFileTypeConfig;
    public onlyRunsOnMobile: boolean = false;
    public showInPlayer: boolean = false;

    private contentFileTypes = FileType;

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get contentFile(): ContentFileModel | null {
        if (this.contentFileID) {
            return ContentListModel.getInstance().getEntityByID(this.contentFileID) as ContentFileModel;
        }
        return null;
    }

    get contentFileIsPDF(): null | boolean {
        return this.fileType === this.contentFileTypes.PORTABLE_DOCUMENT;
    }

    get contentFileIsConvertedPpt(): null | boolean {
        return this.contentFile?.fileType === this.contentFileTypes.PRESENTATION;
    }

    get contentFileIsImage(): null | boolean {
        return this.fileType === this.contentFileTypes.IMAGE;
    }

    get frameUrl(): string {
        return fileManager.getFileUrl(this.contentFile?.fileUri || '');
    }

    public mounted() {
        this.fileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(this.contentFile!.fileUri));
        this.fileTypeConfig = FILE_TYPE_CONFIG[this.fileType];
        this.onlyRunsOnMobile = this.fileTypeConfig.isMobileOnly && !this.$device.ios && !this.$device.android;
        this.showInPlayer = this.fileTypeConfig.showInPlayer;

        if (!this.onlyRunsOnMobile) {
            if (this.engineMode === EngineMode.PRESENT) {
                this.parentSessionIdentifier = audienceSessionController.createAudienceSession(this.contentFile as EntityModel, this.audience!, SessionMode.PRESENT, ActivityType.FILE_SESSION, undefined, undefined, undefined, {fileType: this.fileType}, GamificationTargetIdentifier.PRESENT_CONTENT);
            }
            if (this.showInPlayer) {
                window.addEventListener('message', this.listenToEngine);
            } else {
                this.downloadFile();
            }
        }
    }

    public downloadFile() {
        window.open(fileManager.getFileUrl(this.contentFile!.fileUri), "_blank");
    }

    public beforeDestroy() {
        window.removeEventListener('message', this.listenToEngine);
    }

    public onIframeLoad() {
        const playerFrame: HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
        if (!playerFrame.contentWindow) return;
        playerFrame.contentWindow.focus();
    }

    private async listenToEngine(p_messageEvent: MessageEvent) {
        //todo: check origin

        const message: IEngineMessageDto = p_messageEvent.data;
        if (message.messageType) {

            switch (message.messageType) {
                case  EngineMessageType.ASK_PATCH_AUDIENCE:
                    audienceController.patchBody(this.audience!, message.messageValue);
                    break;

                case  EngineMessageType.REGISTER_AUDIENCE_SESSION:
                    audienceSessionController.registerAudienceSession(message.messageValue);
                    break;

                case  EngineMessageType.REGISTER_AUDIENCE_SESSION_EVENT:
                    (message.messageValue as IAudienceSessionEventDto).audienceID = this.audience!.ID;
                    (message.messageValue as IAudienceSessionEventDto).parentSessionIdentifier = this.parentSessionIdentifier as string;
                    audienceSessionController.registerAudienceSessionEvent(message.messageValue);
                    break;

                case  EngineMessageType.ASK_SAVE:
                    if (this.contentFile) {
                        contentController.saveBody(this.contentFile, message.messageValue);
                    }
                    break;

                case  EngineMessageType.ASK_SHARE_WITH_AUDIENCE:
                    if (this.contentFile) {
                        this.$bvModal.show('share-with-audience-box');
                    }
                    break;

                case  EngineMessageType.ON_NAVIGATION_BOUNDRY:
                    if (this.inPlaylistMode) {
                        PlaylistListModel.getInstance().togglePlaylistPlayer(false);
                    }
                    break;
            }
        } else {
            return;
        }
    }

    public async onBackButtonClick() {
        contentController.stopRemotePresent();
        await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
    }

    public handlePageViewed(p_pageNumber: number, secondsViewed: number) {
        if (this.contentFile && this.audience && this.parentSessionIdentifier && this.engineMode === EngineMode.PRESENT) {
          audienceSessionController.createAudienceSession(this.contentFile, this.audience, SessionMode.PRESENT, ActivityType.PAGE_SESSION, p_pageNumber.toString(), secondsViewed, this.parentSessionIdentifier);
        }
    }

}
</script>
