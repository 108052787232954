import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import AudienceModel from "@/audience/_model/AudienceModel";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IAudienceActivityDto} from "@/audience/track/_model/audience_activity.dto";
import {IAudienceConfigDto, IAudienceFileDto, IGuestDto, IPortalAuthDto} from "@/audience/_model/audience.dto";
import {DtoType, IUploadFileResultDto} from "@/_model/app.constants";
import {IDeleteResultDto} from "@/entity/_model/entity.dto";
import {PortalAuthType} from "@/audience/_model/audience.constants";
import Util from "@/__libs/utility/Util";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";

//contains all (static) controller methods that can be initiated on a single presentation
class AudienceController extends EntityController {


    //---------------------------------
    // Properties
    //---------------------------------


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async fetchAllActivities(p_audience: AudienceModel): Promise<boolean> {
        const response: ApiResponse<IAudienceActivityDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/activities`);

        if (response.hasSucceeded) {
            p_audience.areActivitiesStale = false;
            p_audience.latestActivitiesRetrieval = new Date();
            p_audience.activities = response.result as IAudienceActivityDto[];
        } else {
            p_audience.areActivitiesStale = true;
        }
        return response.hasSucceeded;

    }


    public async fetchConfig(p_audience: AudienceModel) {
        const response: ApiResponse<IAudienceConfigDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/config`);

        if (response.hasSucceeded) {
            p_audience.mapFromDto(response.result!)
        }

    }


    public async fetchFiles(p_audience: AudienceModel) {
        const response: ApiResponse<IAudienceFileDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/files`);

        if (response.hasSucceeded) {
            const files: IAudienceFileDto[] = response.result!;
            for (let i = 0; i < files.length; i++) {
                p_audience.contentShares.push(files[i] as any)

            }
            p_audience.contentShares = p_audience.contentShares.sort((a: any, b: any) => b.shareDate - a.shareDate);
        }
    }

    public async fetchGuests(p_audience: AudienceModel) {
        const response: ApiResponse<IGuestDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/guests`);

        if (response.hasSucceeded) {
            p_audience.guests = [];
            const guests: IGuestDto[] = response.result!;
            for (let i = 0; i < guests.length; i++) {
                p_audience.guests.push(guests[i]);

            }
        }
    }

    public async updatePortalAuth(p_audience:AudienceModel)
    {
        if (p_audience.portalAuthType === PortalAuthType.PINCODE && p_audience.portalSecret.length === 0){
            p_audience.portalSecret = Util.generatePincode()
        }
        const auth:IPortalAuthDto = {
            portalAuthType: p_audience.portalAuthType,
            client:p_audience.ID,
            secret:p_audience.portalSecret,
            clientType:"AUDIENCE"
        };
        await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/portal/auth`, auth);

    }

    public async updateConfig(p_audience: AudienceModel) {
        const config: IAudienceConfigDto = p_audience.mapToDto(DtoType.CONFIG) as IAudienceConfigDto;
        await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/?action=CONFIG`, config);

    }

    public async deleteFile(p_audienceFile: IAudienceFileDto, p_audience: AudienceModel) {
        const endpoint: string = `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/files/${p_audienceFile.audienceFileID}`;
        const response: ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, endpoint);

        if (response.hasSucceeded) {
            const index: number = p_audience.contentShares.indexOf(p_audienceFile as any);
            if (index >= 0) {
                p_audience.contentShares.splice(index, 1);
            }
        }
        return response.hasSucceeded;
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    public async uploadVideoMessage(p_audience: AudienceModel, p_blob: Blob): Promise<boolean> {

        const file:File = new File([p_blob], `${p_audience.ID}.webm`, {type: `${p_blob.type}`})

        const response:ApiResponse<any> = await apiManager.uploadFile(ApiRequestType.POST, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/files`, file, {fileCategoryType:"VIDEO_MESSAGE"});

        if (response.hasSucceeded) {
            p_audience.videoMessageFileUri = response.result!.fileName; //`audienceFile://VIDEO_MESSAGE/${response.result!.fileName}`;
            await this.saveBody(p_audience);
        }
        return response.hasSucceeded;

    }

    public async removeVideoMessage(p_audience: AudienceModel): Promise<boolean> {

        if(p_audience.videoMessageFileUri)
        {
            const audienceFileID = p_audience.videoMessageFileUri.split("_")[0];
            const response: ApiResponse<IDeleteResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, `/client-api/${ENTITY_CONFIG.AUDIENCE.apiPath}/${p_audience.ID}/files/${audienceFileID}`);

            if (response.hasSucceeded) {
                p_audience.videoMessageFileUri = undefined;
                await this.saveBody(p_audience);
            }
            return response.hasSucceeded;
        }
        return false;

    }

}

//Singleton export
export default new AudienceController(ENTITY_CONFIG.AUDIENCE);
