import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route} from 'vue-router';
import InitPage from "@/_view/pages/InitPage.vue";
import AppUserModel from "@/project/user/_model/AppUserModel";

export enum RoutingIdentifier {
    INIT = "init",
    REDIRECT = "redirect",
    LOGIN_CALLBACK = "login-callback",
    LOGOUT = "logout",
    MAIN = "main",
    APP = "app",
    PLAYER = "player",
    PRESENTATION_PLAYER = "presentation-player",
    CONTENT_APP_PLAYER = "content-app-player",
    CONTENT_FILE_PLAYER = "content-file-player",
    PLAYLIST_PLAYER = "playlist-player",
    HOME = "home",
    LIBRARY = "library",
    ASSETS = "assets",
    SLIDES = "slides",
    ANALYTICS = "analytics",
    ANALYTICS_PROJECT = "project-analytics",
    ANALYTICS_TEAM = "team-analytics",
    ANALYTICS_USER = "user-analytics",
    ANALYTICS_CONTENT = "content-analytics",
    HELP = "help",
    PEOPLE = "people",
    CONTENT = "content",
    CONTENT_OVERVIEW = "content-overview",
    CONTENT_PREPARE = "content-prepare",
    SHARE = "share",
    TRACK = "track",
    AUDIENCE_DETAIL = "audience-detail",
    AUDIENCE_SHARE = "audience-share",
    AUDIENCE_TRACK = "audience-track",
    PORTAL_SETTINGS = "portal-settings",
    TEAMS = "teams",
    TEAMUSERS = "teamusers",
    ROLES = "roles",
    TARGETS = "targets",
    AUDIENCES = "audiences",
    COMPANIES = "companies",
    DATA_PROVIDERS = "data-providers",
    SETTINGS = "settings",
    APP_USER = "app-user",
    APP_USER_2 = "app-user-2",
    CRM = "crm",
    PROJECT = "project",
    GAMIFICATION = "gamification",
    TARGET_TRACKING = "target-tracking",
    WELCOME = "welcome",
    WELCOME_START = "welcome-start",
    WELCOME_LOGO = "welcome-logo",
    WELCOME_COLOR = "welcome-color",
    WELCOME_FONT = "welcome-font",
    WELCOME_FINISH = "welcome-finish",
    ACCOUNT = "account",
}

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "hash",
    routes: [
        {
            path: '/',
            name: RoutingIdentifier.INIT,
            component: InitPage,
            meta: {
                requiresAuth: false
            },
            beforeEnter: (_: Route, __: Route, next: NavigationGuardNext) => {
                if (window.location.origin.includes('audience-advantage.app')) {
                    next({name: RoutingIdentifier.REDIRECT});
                } else {
                    next();
                }
            }
        },
        {
            path: '/redirect',
            name: RoutingIdentifier.REDIRECT,
            component: () => import('@/_view/pages/RedirectSplashPage.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/login/callback/:code',
            name: RoutingIdentifier.LOGIN_CALLBACK,
            component: InitPage,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/logout',
            name: RoutingIdentifier.LOGOUT,
            component: InitPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: `/${RoutingIdentifier.WELCOME}`,
            name: RoutingIdentifier.WELCOME,
            component: () => import('@/project/welcome/_view/WelcomePage.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: `/${RoutingIdentifier.WELCOME_START}`,
                    name: RoutingIdentifier.WELCOME_START,
                    component: () => import('@/project/welcome/_view/WelcomeStartPage.vue')
                },
                {
                    path: `/${RoutingIdentifier.WELCOME_LOGO}`,
                    name: RoutingIdentifier.WELCOME_LOGO,
                    component: () => import('@/project/welcome/_view/WelcomeLogoPage.vue')
                },
                {
                    path: `/${RoutingIdentifier.WELCOME_COLOR}`,
                    name: RoutingIdentifier.WELCOME_COLOR,
                    component: () => import('@/project/welcome/_view/WelcomeColorPage.vue')
                },
                {
                    path: `/${RoutingIdentifier.WELCOME_FONT}`,
                    name: RoutingIdentifier.WELCOME_FONT,
                    component: () => import('@/project/welcome/_view/WelcomeFontPage.vue')
                },
                {
                    path: `/${RoutingIdentifier.WELCOME_FINISH}`,
                    name: RoutingIdentifier.WELCOME_FINISH,
                    component: () => import('@/project/welcome/_view/WelcomeFinishPage.vue')
                }
            ]
        },
        {
            path: `/${RoutingIdentifier.MAIN}`,
            name: RoutingIdentifier.MAIN,
            component: () => import('@/_view/pages/MainPage.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: `/${RoutingIdentifier.APP}`,
                    name: RoutingIdentifier.APP,
                    component: () => import('@/_view/pages/AppPage.vue'),
                    props: true,
                    redirect: `/${RoutingIdentifier.HOME}`,
                    children: [
                        {
                            path: `/${RoutingIdentifier.HOME}`,
                            name: RoutingIdentifier.HOME,
                            component: () => import('@/_view/pages/HomePage.vue'),
                            redirect: `/${RoutingIdentifier.CONTENT}`,
                            children: [
                                {
                                    path: `/${RoutingIdentifier.CONTENT}`,
                                    name: RoutingIdentifier.CONTENT,
                                    component: () => import('@/content/_view/ContentPage.vue'),
                                    redirect: `/${RoutingIdentifier.CONTENT_OVERVIEW}`,
                                    children: [
                                        {
                                            path: `/${RoutingIdentifier.CONTENT_OVERVIEW}`,
                                            name: RoutingIdentifier.CONTENT_OVERVIEW,
                                            component: () => import('@/content/_view/ContentOverview.vue')
                                        },
                                        {
                                            path: `/${RoutingIdentifier.CONTENT_PREPARE}`,
                                            name: RoutingIdentifier.CONTENT_PREPARE,
                                            component: () => import('@/content/_view/ContentPrepare.vue'),
                                            props: true
                                        }
                                    ]
                                },
                                {
                                    path: `/${RoutingIdentifier.SHARE}`,
                                    name: RoutingIdentifier.SHARE,
                                    component: () => import('@/audience/share/_view/SharePage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.TRACK}`,
                                    name: RoutingIdentifier.TRACK,
                                    component: () => import('@/audience/track/_view/TrackPage.vue')
                                }
                            ]
                        },
                        {
                            path: `/${RoutingIdentifier.TARGETS}`,
                            name: RoutingIdentifier.TARGETS,
                            component: () => import('@/_view/pages/TargetsPage.vue'),
                            redirect: `/${RoutingIdentifier.AUDIENCES}`,
                            children: [
                                {
                                    path: `/${RoutingIdentifier.AUDIENCES}`,
                                    name: RoutingIdentifier.AUDIENCES,
                                    component: () => import('@/audience/_view/AudiencesPage.vue'),
                                    redirect: `/${RoutingIdentifier.AUDIENCE_DETAIL}`,
                                    children: [
                                        {
                                            path: `/${RoutingIdentifier.AUDIENCE_DETAIL}`,
                                            name: RoutingIdentifier.AUDIENCE_DETAIL,
                                            component: () => import('@/audience/_view/AudienceDetailPage.vue')
                                        },
                                        {
                                            path: `/${RoutingIdentifier.AUDIENCE_SHARE}`,
                                            name: RoutingIdentifier.AUDIENCE_SHARE,
                                            component: () => import('@/audience/share/_view/SharePage.vue')
                                        },
                                        {
                                            path: `/${RoutingIdentifier.AUDIENCE_TRACK}`,
                                            name: RoutingIdentifier.AUDIENCE_TRACK,
                                            component: () => import('@/audience/track/_view/TrackPage.vue')
                                        },
                                        {
                                            path: `/${RoutingIdentifier.PORTAL_SETTINGS}`,
                                            name: RoutingIdentifier.PORTAL_SETTINGS,
                                            component: () => import('@/audience/portal_settings/_view/PortalSettingsPage.vue')
                                        }
                                    ]
                                },
                                {
                                    path: `/${RoutingIdentifier.COMPANIES}`,
                                    name: RoutingIdentifier.COMPANIES,
                                    component: () => import('@/company/_view/CompaniesView.vue')
                                }
                            ]
                        },
                        {
                            path: `/${RoutingIdentifier.LIBRARY}`,
                            name: RoutingIdentifier.LIBRARY,
                            component: () => import('@/_view/pages/LibraryPage.vue'),
                            redirect: `/${RoutingIdentifier.ASSETS}`,
                            children: [
                                {
                                    path: `/${RoutingIdentifier.ASSETS}`,
                                    name: RoutingIdentifier.ASSETS,
                                    component: () => import('@/asset_folder/_view/AssetFoldersPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.SLIDES}`,
                                    name: RoutingIdentifier.SLIDES,
                                    component: () => import('@/slide/_view/SlidesPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.DATA_PROVIDERS}`,
                                    name: RoutingIdentifier.DATA_PROVIDERS,
                                    component: () => import('@/data_tool/data_provider/_view/DataProvidersPage.vue')
                                },
                            ]
                        },
                        {
                            path: `/${RoutingIdentifier.ANALYTICS}`,
                            name: RoutingIdentifier.ANALYTICS,
                            component: () => import('@/analytics/_view/AnalyticsPage.vue'),
                            children: [
                                {
                                    path: `/${RoutingIdentifier.ANALYTICS_PROJECT}`,
                                    name: RoutingIdentifier.ANALYTICS_PROJECT,
                                    component: () => import('@/analytics/_view/AnalyticsProjectPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.ANALYTICS_TEAM}`,
                                    name: RoutingIdentifier.ANALYTICS_TEAM,
                                    component: () => import('@/analytics/_view/team/AnalyticsTeamPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.ANALYTICS_USER}`,
                                    name: RoutingIdentifier.ANALYTICS_USER,
                                    component: () => import('@/analytics/_view/user/AnalyticsUserPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.ANALYTICS_CONTENT}`,
                                    name: RoutingIdentifier.ANALYTICS_CONTENT,
                                    component: () => import('@/analytics/_view/content/AnalyticsContentPage.vue')
                                }
                            ]
                        },
                        {
                            path: `/${RoutingIdentifier.HELP}`,
                            name: RoutingIdentifier.HELP,
                            component: () => import('@/help/_view/HelpPage.vue')
                        },
                        {
                            path: `/${RoutingIdentifier.PEOPLE}`,
                            name: RoutingIdentifier.PEOPLE,
                            component: () => import('@/team/_view/PeoplePage.vue'),
                            redirect: `/${RoutingIdentifier.TEAMUSERS}`,
                            children: [
                                {
                                    path: `/${RoutingIdentifier.TEAMUSERS}`,
                                    name: RoutingIdentifier.TEAMUSERS,
                                    component: () => import('@/team/_view/TeamUsersView.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.TEAMS}`,
                                    name: RoutingIdentifier.TEAMS,
                                    component: () => import('@/team/_view/TeamsView.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.ROLES}`,
                                    name: RoutingIdentifier.ROLES,
                                    component: () => import('@/team/_view/RolesView.vue')
                                }
                            ]
                        },
                        {
                            path: `/${RoutingIdentifier.ACCOUNT}`,
                            name: RoutingIdentifier.ACCOUNT,
                            component: () => import('@/_view/pages/AccountPage.vue'),
                            redirect: `/${RoutingIdentifier.APP_USER}`,
                            children: [
                                {
                                    path: `/${RoutingIdentifier.APP_USER}`,
                                    name: RoutingIdentifier.APP_USER,
                                    component: () => import('@/project/user/_view/AppUserPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.GAMIFICATION}`,
                                    name: RoutingIdentifier.GAMIFICATION,
                                    component: () => import('@/gamification/_view/GamificationPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.TARGET_TRACKING}`,
                                    name: RoutingIdentifier.TARGET_TRACKING,
                                    component: () => import('@/_view/pages/CustomPage.vue'),
                                    props: true
                                },
                                {
                                    path: `/${RoutingIdentifier.PROJECT}`,
                                    name: RoutingIdentifier.PROJECT,
                                    component: () => import('@/project/_view/ProjectSettingsPage.vue')
                                },
                                {
                                    path: `/${RoutingIdentifier.CRM}`,
                                    name: RoutingIdentifier.CRM,
                                    component: () => import('@/crm/_view/CRMView.vue')
                                }
                            ]
                        }
                    ]
                },
                {
                    path: `/${RoutingIdentifier.PLAYER}`,
                    name: RoutingIdentifier.PLAYER,
                    component: () => import('@/_view/pages/PlayerPage.vue'),
                    children: [
                        {
                            path: `/${RoutingIdentifier.PRESENTATION_PLAYER}`,
                            name: RoutingIdentifier.PRESENTATION_PLAYER,
                            component: () => import('@/presentation/player/_view/PresentationPlayerPage.vue'),
                            meta: {
                                requiresAuth: true
                            },
                            props: true
                        },
                        {
                            path: `/${RoutingIdentifier.CONTENT_APP_PLAYER}`,
                            name: RoutingIdentifier.CONTENT_APP_PLAYER,
                            component: () => import('@/content_app/player/_view/ContentAppPlayerPage.vue'),
                            meta: {
                                requiresAuth: true
                            },
                            props: true
                        },
                        {
                            path: `/${RoutingIdentifier.CONTENT_FILE_PLAYER}`,
                            name: RoutingIdentifier.CONTENT_FILE_PLAYER,
                            component: () => import('@/content_file/player/_view/ContentFilePlayerPage.vue'),
                            meta: {
                                requiresAuth: true
                            },
                            props: true
                        },
                        {
                            path: `/${RoutingIdentifier.PLAYLIST_PLAYER}`,
                            name: RoutingIdentifier.PLAYLIST_PLAYER,
                            component: () => import('@/playlist/player/_view/PlaylistPlayerPage.vue'),
                            meta: {
                                requiresAuth: true
                            },
                            props: true
                        }
                    ]
                }
            ]
        },
    ],
});

router.beforeEach((to: Route, from: Route, next: any) => {
    // also here check if token is expired?
    if (to.matched.some(m => m.meta.requiresAuth) && !AppUserModel.getInstance().isAuthenticated) {
        next({path: '/'});
    } else {
        next();
    }
});

export default router;
