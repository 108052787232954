<template>
  <div class="box box--full-height box--scrollable audience-detail">
    <transition name="fade">
      <div v-if="!audience"
           class="empty-message">
        {{ $t('DashboardPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="audience && !bodyLoaded"
           class="empty-loader">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="audience && bodyLoaded" :key="`${audience.ID}`" class="box-wrapper">

        <div class="detail-header">
          <div class="detail-header__person">
            <AAProfilePicture :name="audience.displayName"
                              :imageUri="audience.avatarFileUri"
                              :editable="true"
                              :big="true"></AAProfilePicture>
            <div>
              <h4 class="title mb-0">{{ audience.displayName }}</h4>
              <AAImage v-if="audienceIsImported" :imageUrl="crmLogoPath" class="audience-detail__crm"/>
            </div>
          </div>
          <div class="detail-header__buttons">
            <b-button variant="outline-secondary" block class="action-btn mb-2"
                      @click="_onOpenAssetPickerBtnClick"
                      :disabled="!online">
              <i class="fas fa-image fa-lg"></i>{{ $t('AddProfilePicture') }}
            </b-button>
            <EntityActionBox :entity="audience" :editAllowed="false"></EntityActionBox>
          </div>
        </div>

        <div class="detail-inputs">
          <b-form-group :label="$t('PlaceholderDisplayName')">
            <b-form-input v-model="validationAudience.displayName"
                          :disabled="!audience.isEditable || !online"
                          :state="_calculateInputState($v.validationAudience.displayName)"
                          @input="audience.displayName = _onFormValidationInput($v.validationAudience.displayName, audience.displayName)"></b-form-input>
            <b-form-invalid-feedback>
            <span class="d-block"
                  v-if="!$v.validationAudience.displayName.required">{{
                $t('FieldRequiredSimple')
              }}</span>
              <span class="d-block"
                    v-if="!$v.validationAudience.displayName.minLength">{{
                  $t('MinimumFieldLengthSimple', [3])
                }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderEmail')">
            <b-form-input type="email"
                          v-model="validationAudience.email"
                          :disabled="!audience.isEditable || !online"
                          :state="_calculateInputState($v.validationAudience.email)"
                          @input="audience.email = _onFormValidationInput($v.validationAudience.email, audience.email)"></b-form-input>
            <b-form-invalid-feedback v-if="$v.validationAudience.email">
                        <span class="d-block" v-if="!$v.validationAudience.email.required">{{
                            $t('FieldRequiredSimple')
                          }}</span>
              <span class="d-block"
                    v-if="!$v.validationAudience.email.email">{{ $t('ErrorInvalidEmailAddress') }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderFirstName')">
            <b-form-input v-model="audience.firstName"
                          :disabled="!audience.isEditable || !online"
                          @input="_onFormInput"></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderLastName')">
            <b-form-input v-model="audience.lastName"
                          :disabled="!audience.isEditable || !online"
                          @input="_onFormInput"></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('PlaceholderPersonLanguage')">
            <b-form-select v-model="audience.langCode"
                           :disabled="!audience.isEditable || !online"
                           @change="_onFormInput">
              <option v-for="langCode in availableLanguages" :key="langCode"
                      :value="langCode">{{ langCode }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>

        <div class="sub-box audience-detail__company">
          <b-form-group>
            <label>
              <i class="fas fa-building"></i>
              {{ $t('AudienceDetailCompany') }}
            </label>
            <b-form-select v-model="audience.companyID"
                           :disabled="!audience.isEditable || !online"
                           @change="_onFormInput">
              <option v-for="company in companies" :key="company.ID"
                      :value="company.ID">{{ company.displayName }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>

        <h6 class="title" v-html="$t('SyncWidgetExtraInfo')" v-if="audience.dataDtp"></h6>
        <DataProviderEditor :dataProvider="audience.dataDtp" :isStandalone="true" v-if="audience.dataDtp"/>

        <div class="sub-box team-users-detail__box mt-3" v-if="showGuests">
          <h6 class="d-flex"><i class="fas fa-user-friends"></i>{{ $t('Guests') }}</h6>
          <ul>
            <li v-for="guest in audience.guests" :key="guest.guestID">
              {{ guest.displayName }} <i>({{ guest.email }})</i>
            </li>
          </ul>
        </div>

        <h6 class="title" v-html="$t('AudienceDetails')"></h6>
        <table class="table table-sm table-striped table-borderless">
          <tbody>
          <tr>
            <th scope="row">{{ $t('ConsentGivenOn') }}</th>
            <td>{{ consentDate }}</td>
          </tr>
          </tbody>
        </table>

      </div>
    </transition>

    <b-modal id="audience-asset-picker-box" size="xl" :title="$t('PickAudienceAvatarOrUploadNewOne')" hide-footer>
      <AssetFoldersPicker v-if="audience"
                          :selectedFileUri="audience.avatarFileUri"
                          :allowedTargetType="avatarAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";

import audienceController from "@/audience/_controller/AudienceController";
import languageManager from "@/__libs/language_manager/LanguageManager";
import {email, minLength, required} from "vuelidate/lib/validators";
import DataProviderEditor from "@/data_tool/data_provider/_view/DataProviderEditor.vue";
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyListModel from "@/company/_model/CompanyListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import NetworkManager from "@/_controller/NetworkManager";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import fileManager from "@/_controller/FileManager";
import AAImage from "@/_view/components/AAImage.vue";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";

@Component({
    components: {
        EntityActionBox,
        AAProfilePicture,
        AAImage,
        AssetFoldersPicker,
        DataProviderEditor
    },
    validations: {
        validationAudience: {
            displayName: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                email
            }
        }
    }
})
export default class AudienceDetail extends Vue {

    public validationAudience: AudienceModel = new AudienceModel();
    public networkManager: NetworkManager = NetworkManager.getInstance();
    public avatarAllowedTargetType: FileTargetType = FileTargetType.AUDIENCE_AVATAR;
    public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];

    get bodyLoaded() {
        return this.audience !== null && this.audience.loadingStatus >= LoadingStatus.BODY_LOADED;
    }

    get online() {
        return this.networkManager.online;
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get availableLanguages(): string[] {
        return languageManager.availableLangCodes;
    }

    get companies(): CompanyModel[] {
        const noCompany: CompanyModel = new CompanyModel({displayName: this.$t('NoCompany')});
        return [noCompany, ...CompanyListModel.getInstance().list];
    }

    get hasChanges() {
        return this.audience && this.audience.hasChanges;
    }

    get showGuests(): boolean {
        return AppUserModel.getInstance().project.tierConfig.hasGuests && this.audience!.guests!.length > 0;
    }

    public beforeDestroy() {
        if (this.audience && this.audience.hasChanges) {
            this._saveAudience(this.audience);
        }
    }

    private _calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    private _saveAudience(audience: AudienceModel): void {
        audienceController.saveBody(audience);
    }

    private _createValidationAudience(audience: AudienceModel): void {
        if (this.audience) {
            this.validationAudience = new AudienceModel();
            this.validationAudience.displayName = audience.displayName;
            this.validationAudience.email = audience.email;
        }
    }

    private _onFormValidationInput(inputValidator: any, value: any): void {
        if (!inputValidator.$invalid && this.audience) {
            value = inputValidator.$model;
            this._onFormInput(null);
        }
        return value;
    }

    private _onFormInput(p_e: Event | null) {
        if (this.audience) {
            this.audience.hasChanges = true;
        }
    }

    @Watch('audience', {immediate: true, deep: false})
    private _onActiveAudienceChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
        if (oldAudience && oldAudience.hasChanges) {
            this._saveAudience(oldAudience);
        }
        if (newAudience) {
            this._createValidationAudience(newAudience);
            audienceController.fetchGuests(newAudience);
        }
    }

    @Watch('bodyLoaded', {immediate: true, deep: false})
    private _onBodyLoadedChange(newBodyLoaded: boolean, oldBodyLoaded: boolean) {
        if (newBodyLoaded) {
            this._createValidationAudience(this.audience!);
        }
    }

    //todo: also watch window.beforeunload

    get audienceIsImported(): boolean {
        return this.audience!.importedFrom !== 'NONE';
    }

    get crmLogoPath() {
        return `img/crm/${this.audience!.importedFrom}.png`;
    }

    get consentDate(): string {
        return this.audience!.consentDate ? this.$d(new Date(this.audience!.consentDate!), 'short') : "-";
    }

    private _onOpenAssetPickerBtnClick(p_e: Event) {
        this.$bvModal.show('audience-asset-picker-box');
    }

    private _onAssetFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide('audience-asset-picker-box');
        if (p_assetFile && this.audience) {
            this.audience.avatarFileUri = p_assetFile.fileURI;
            this.audience.hasChanges = true;
        }
    }

}
</script>
