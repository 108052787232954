<template>
  <div class="portal-video-record-box">
    <p v-html="$t('PortalVideoRecordBoxText')" class="portal-video-record-box__text"></p>
    <div class="portal-video-record-box__preview">
      <video ref="videoStream" id="record-video" autoplay></video>
      <video ref="videoPlayback" id="playback-video" controls v-show="showPlayback"></video>
    </div>

    <transition name="fade">
      <div class="portal-video-record-box__status" v-if="currentState === state.ACTIVE">
        <b-button @click="start" variant="success" v-if="showStartButton">
          {{ $t('PortalVideoRecordBoxStart') }}
        </b-button>

        <b-button @click="stop" v-if="showStopButton">{{ $t('PortalVideoRecordBoxStop') }}</b-button>
        <b-button @click="save" variant="primary" v-if="showSaveButton">{{ $t('PortalVideoRecordBoxSave') }}</b-button>
        <b-button @click="clearRecording" v-if="showClearButton">{{ $t('PortalVideoRecordBoxClear') }}</b-button>

        <div v-if="isRecording" class="portal-video-record-box__status__recording">
          <b-spinner small type="grow" variant="danger"></b-spinner>

          <div v-html="$t('PortalVideoRecordBoxRecording')" class="small text-danger"></div>

          <countdown :time="59 * 1000" :auto-start="false" ref="recordingCountdown" @end="stop" class="portal-video-record-box__status__recording__countdown">
            <template v-slot="{ seconds }">
              {{ seconds }}
              <small class="portal-video-record-box__status__recording__countdown__left">
                {{ $t('PortalVideoRecordBoxCountdown') }}
              </small>
            </template>
          </countdown>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="portal-video-record-box__verify" v-if="currentState !== state.ACTIVE">
        <b-spinner variant="dark" v-if="currentState === state.WORKING"></b-spinner>

        <p v-if="currentState === state.ERROR_NO_CAMERA" class="text-danger mb-0">
          {{ $t('PortalVideoRecordBoxErrorCamera') }}
        </p>

        <p v-if="currentState === state.ERROR_SERVER" class="text-danger mb-0">
          {{ $t('PortalVideoRecordBoxErrorServer') }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import audienceController from "@/audience/_controller/AudienceController";

enum State {
    WORKING,
    ACTIVE,
    ERROR_NO_CAMERA,
    ERROR_SERVER
}

@Component({})
export default class PortalVideoRecordBox extends Vue {
    $refs!: {
        videoStream: HTMLVideoElement;
        videoPlayback: HTMLVideoElement;
        recordingCountdown: any;
    };

    public readonly state: typeof State = State
    public currentState: State = State.WORKING;
    public isRecording: boolean = false;

    private mediaRecorder?: MediaRecorder;
    private mediaStream?: MediaStream;
    private recordedChunks: BlobPart[] = [];
    private recordedBlob: Blob | null = null;

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get showStartButton(): boolean {
        return !this.isRecording && this.recordedBlob === null;
    }

    get showStopButton(): boolean {
        return this.isRecording;
    }

    get showClearButton(): boolean {
        return !this.isRecording && this.recordedBlob !== null;
    }

    get showPlayback(): boolean {
        return !this.isRecording && this.recordedBlob !== null;
    }

    get showSaveButton(): boolean {
        return !this.isRecording && this.recordedBlob !== null;
    }

    public created() {
        this.initRecorder();
    }

    public async start() {
      if (this.isRecording || !this.$refs.videoStream) return;

      this.isRecording = true;

      // Wait for video to be ready
      if (this.$refs.videoStream.readyState < 3) {
        await new Promise(resolve => this.$refs.videoStream.onplaying = resolve);
      }

      if (this.mediaRecorder) {
        this.mediaRecorder.start();
        await Vue.nextTick();
        this.$refs.recordingCountdown.start();
      }
    }

    public stop() {
      if (!this.isRecording || !this.mediaRecorder || !this.mediaStream) return;

      this.isRecording = false;
      this.mediaRecorder.stop();
      this.mediaStream.getTracks().forEach(track => track.stop());
    }

    private async initRecorder() {
      if (this.isRecording) return;

      try {
        this.mediaStream = await this.getStream();
        this.$refs.videoStream.srcObject = this.mediaStream;
        this.$refs.videoStream.muted = true;
        this.prepareRecorder();

        if (!this.mediaRecorder) {
          this.currentState = State.ERROR_NO_CAMERA;
          return;
        }

        this.currentState = State.ACTIVE;
      } catch (e: any) {
        console.error(e);
      }
    }

    private async getStream() {
      return await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      });
    }

    private prepareRecorder() {
      if (!this.mediaStream) return;

      this.mediaRecorder = new MediaRecorder(this.mediaStream, {
        mimeType: 'video/webm'
      });

      this.mediaRecorder.addEventListener('start', () => {
        this.isRecording = true;
      });

      this.mediaRecorder.addEventListener('dataavailable', (e) => {
        if (e.data && e.data.size > 0) {
          this.recordedChunks.push(e.data);
        }
      }, true);

      this.mediaRecorder.addEventListener('stop', () => {
        this.isRecording = false;
        this.recordedBlob = new Blob(this.recordedChunks, { type: 'video/webm' });
        this.recordedChunks = [];

        if (this.$refs.videoPlayback) {
          this.$refs.videoPlayback.src = URL.createObjectURL(this.recordedBlob);
          this.$refs.videoPlayback.play();
        }
      });
    }

    public clearRecording() {
        this.recordedChunks = [];
        this.recordedBlob = null;
        this.$refs.videoPlayback.src = '';
        this.initRecorder();
    }

    public destroyed() {
        this.stop();
    }

    public async save() {
        if (this.recordedBlob) {
            this.currentState = State.WORKING;
            const success: boolean = await audienceController.uploadVideoMessage(this.audience!, this.recordedBlob!);
            if (success) {
                this.$bvModal.hide('portal-video-record-box');
            } else {
                this.currentState = State.ERROR_SERVER;
            }
        }
    }
}
</script>
