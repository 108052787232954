<template>
  <div class="home-sub-page portal-settings-page">
    <transition name="fade">
      <div v-if="!audience"
           class="empty-message">
        {{ $t('DashboardPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="audience && !bodyLoaded"
           class="empty-loader">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="audience && bodyLoaded" :key="`${audience.ID}`" class="box box--full-height box--scrollable">

        <div class="box box--bordered portal-settings-page__settings">
          <b-button @click="_onPortalBtnClick" variant="primary" block class="preview-button mb-3" :disabled="!online">
            {{
              $t('DashboardSharedViewVisitorPageButtonText', [audience.displayName])
            }}
          </b-button>

          <div class="mb-3">
            <b-input-group :prepend="$t('LinkToPortal')">
              <b-form-input :value="getAudiencePortalLink(false)" ref="portalLink"></b-form-input>
              <b-input-group-append>
                <b-button variant="success" @click="_onCopyLinkBtnClick"><i class="fa-solid fa-copy mr-0 fa-lg"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <transition name="fade">
              <div v-if="currentCopyToClipboardState === copyToClipboardState.SUCCESS"
                   class="text-success small mt-1 text-center">{{ $t('ClipboardSuccess') }}
              </div>
            </transition>
            <transition name="fade">
              <div v-if="currentCopyToClipboardState === copyToClipboardState.ERROR"
                   class="text-danger small mb-1 text-center">{{ $t('ClipboardError') }}
              </div>
            </transition>
          </div>

          <b-form-group>
            <b-form-checkbox @input="_onNotifyPortalVisitChange"
                             v-model="audienceNotifyPortalVisit" :disabled="!online">{{
                $t('NotifyMeWhenUserVisitsPortal')
              }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div v-if="showPortalSecurity" class="sub-box portal-settings-page__security">
          <label class="d-block">
            <i class="fas fa-lock"></i>{{ $t('SecurePortal') }}
          </label>
          <b-form-select v-model="audience.portalAuthType" @input="onPortalAuthChanged">
            <b-form-select-option v-for="portalAuthTypeFilter in availablePortalAuthTypeFilters"
                                  :value="portalAuthTypeFilter"
                                  :key="portalAuthTypeFilter">{{ $t(`PortalAuthTypeFilters_${portalAuthTypeFilter}`) }}
            </b-form-select-option>
          </b-form-select>
          <transition name="fade">
            <b-form-group :label="$t('PlaceholderPincode')" v-if="audience.portalAuthType === portalAuthType.PINCODE" class="mt-2">
              <b-form-input type="number"
                            v-model="validationAudience.portalSecret"
                            :disabled="!online"
                            :state="_calculateInputState($v.validationAudience.portalSecret)"
                            @input="audience.portalSecret = _onFormValidationInput($v.validationAudience.portalSecret, audience.portalSecret)"
                            @blur="onPortalAuthChanged"></b-form-input>
              <b-form-invalid-feedback v-if="$v.validationAudience.portalSecret">
                        <span class="d-block" v-if="!$v.validationAudience.portalSecret.required">{{
                            $t('FieldRequiredSimple')
                          }}</span>
                <span class="d-block"
                      v-if="!$v.validationAudience.portalSecret.minLength || !$v.validationAudience.portalSecret.maxLength">{{
                    $t('ErrorPincodeLength')
                  }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </transition>
        </div>

        <div class="sub-box portal-settings-page__splash" v-if="showSplashPicker">
          <label class="d-block">
            <i class="fas fa-display"></i>{{ $t('AudienceDetailSplash') }}
          </label>
          <div v-if="splashFileUrl || splashVideoFileUrl" class="portal-settings-page__splash__preview mb-3">
            <AAImage :image-url="splashFileUrl" v-if="splashFileUrl"/>
            <video controls muted v-if="splashVideoFileUrl">
              <source :src="splashVideoFileUrl" type="video/mp4">
            </video>
          </div>
          <div class="portal-settings-page__splash__buttons">
            <b-button @click="_onOpenSplashPickerBtnClick" :disabled="!online">{{
                $t('AudienceDetailSplashImageButton')
              }}
            </b-button>
            <b-button @click="_onOpenSplashVideoPickerBtnClick" :disabled="!online">{{
                $t('AudienceDetailSplashVideoButton')
              }}
            </b-button>
            <b-button @click="_onOpenSplashPickerRemoveBtnClick" variant="danger"
                      v-if="splashFileUrl || splashVideoFileUrl"
                      :disabled="!online">
              {{ $t('AudienceDetailSplashRemoveButton') }}
            </b-button>
          </div>
        </div>

        <div class="sub-box portal-settings-page__video">
          <label class="d-block">
            <i class="fas fa-video"></i>{{ $t('PortalVideoTitle') }}
          </label>
          <div v-if="videoMessageFileUrl" class="portal-settings-page__video__preview mb-3">
            <video controls>
              <source :src="videoMessageFileUrl" type="video/mp4">
            </video>
          </div>
          <div class="portal-settings-page__video__buttons">
            <b-button @click="openVideoRecordBox" :disabled="!online">{{
                $t('PortalVideoAddButton')
              }}
            </b-button>
            <b-button @click="removeVideo" variant="danger" v-if="videoMessageFileUrl" :disabled="!online">
              {{ $t('PortalVideoRemoveButton') }}
            </b-button>
          </div>
        </div>

      </div>
    </transition>

    <b-modal id="audience-splash-picker-box" size="xl" :title="$t('AudienceDetailSplashTitle')" hide-footer>
      <AssetFoldersPicker v-if="audience"
                          :selectedFileUri="audience.splashFileUri"
                          :allowedTargetType="splashAllowedTargetType"
                          :allowedFileTypes="splashAllowedFileTypes"
                          v-on:onAssetFilePicked="_onSplashFilePicked"></AssetFoldersPicker>
    </b-modal>

    <b-modal id="audience-splash-video-picker-box" size="xl" :title="$t('AudienceDetailSplashTitle')" hide-footer>
      <AssetFoldersPicker v-if="audience"
                          :selectedFileUri="audience.splashVideoFileUri"
                          :allowedTargetType="splashAllowedTargetType"
                          :allowedFileTypes="splashVideoAllowedFileTypes"
                          v-on:onAssetFilePicked="_onSplashVideoFilePicked"></AssetFoldersPicker>
    </b-modal>

    <b-modal id="portal-video-record-box" size="lg" :title="$t('PortalVideoBoxTitle')" hide-footer>
      <PortalVideoRecordBox v-if="audience"></PortalVideoRecordBox>
    </b-modal>

    <b-modal id="portal-preview-visitor-page-box" size="xl"
             :title="$t('DashboardSharedViewVisitorPageButtonText', [audienceDisplayName]) " hide-footer>
      <PreviewVisitorPageBox v-if="audience"
                             :portalLink="getAudiencePortalLink(true)"
      ></PreviewVisitorPageBox>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import AudienceSummary from "@/audience/_view/AudienceSummary.vue";
import AudienceDetail from "@/audience/_view/AudienceDetail.vue";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import fileManager from "@/_controller/FileManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import NetworkManager from "@/_controller/NetworkManager";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import audienceController from "@/audience/_controller/AudienceController";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import AAImage from "@/_view/components/AAImage.vue";
import PortalVideoRecordBox from "@/audience/portal_settings/_view/PortalVideoRecordBox.vue";
import PreviewVisitorPageBox from "@/audience/_view/PreviewVisitorPageBox.vue";
import AppModel from "@/_model/AppModel";
import {PortalAuthType} from "@/audience/_model/audience.constants";
import {email, maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

enum CopyToClipboardState {
    IDLE,
    SUCCESS,
    ERROR
}

@Component({
    components: {PreviewVisitorPageBox, PortalVideoRecordBox, AAImage, AssetFoldersPicker},
    validations: {
        validationAudience: {
            portalSecret: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(4)
            }
        }
    }
})
export default class PortalSettingsPage extends Vue {
    public networkManager: NetworkManager = NetworkManager.getInstance();
    public splashAllowedTargetType: FileTargetType = FileTargetType.GENERAL_DOCUMENT;
    public splashAllowedFileTypes: FileType[] = [FileType.IMAGE];
    public splashVideoAllowedFileTypes: FileType[] = [FileType.VIDEO];
    private copyToClipboardState: typeof CopyToClipboardState = CopyToClipboardState;
    private currentCopyToClipboardState: CopyToClipboardState = CopyToClipboardState.IDLE;
    public availablePortalAuthTypeFilters: PortalAuthType[] = [PortalAuthType.LINK_ONLY, PortalAuthType.PINCODE];
    public portalAuthType: typeof PortalAuthType = PortalAuthType;
    public validationAudience: AudienceModel = new AudienceModel();

    get splashFileUrl(): string | null {
        if (this.audience && this.audience.splashFileUri) {
            return fileManager.getFileUrl(this.audience.splashFileUri);
        }
        return null;
    }

    get splashVideoFileUrl(): string | null {
        if (this.audience && this.audience.splashVideoFileUri) {
            return fileManager.getFileUrl(this.audience.splashVideoFileUri);
        }
        return null;
    }

    get videoMessageFileUrl(): string | null {
        if (this.audience && this.audience.videoMessageFileUri) {
            return AppUserModel.getInstance().project.storageUrl + '/audiences/files/VIDEO_MESSAGE/' + this.audience.videoMessageFileUri;
        }
        return null;
    }

    get online() {
        return this.networkManager.online;
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get bodyLoaded() {
        return this.audience !== null && this.audience.loadingStatus >= LoadingStatus.BODY_LOADED;
    }

    get hasChanges() {
        return this.audience && this.audience.hasChanges;
    }

    get showSplashPicker(): boolean {
        return AppUserModel.getInstance().project.tierConfig.hasBrandedPortal;
    }

    // security

    get audienceNotifyPortalVisit(): boolean {
        return this.audience?.notifyPortalVisit ?? false;
    }

    set audienceNotifyPortalVisit(p_value: boolean) {
        if (this.audience) {
            this.audience.notifyPortalVisit = p_value;
        }
    }

    get showPortalSecurity(): boolean {
        return AppUserModel.getInstance().project.tierConfig.hasPortalSecurity;
    }

    public onPortalAuthChanged() {
        if (this.audience) {
            audienceController.updatePortalAuth(this.audience);
        }
    }

    // splash

    public _onOpenSplashPickerBtnClick(p_e: Event) {
        this.$bvModal.show('audience-splash-picker-box');
    }

    public _onOpenSplashVideoPickerBtnClick(p_e: Event) {
        this.$bvModal.show('audience-splash-video-picker-box');
    }

    public _onOpenSplashPickerRemoveBtnClick(p_e: Event) {
        if (this.audience) {
            this.audience.splashFileUri = undefined;
            this.audience.splashVideoFileUri = undefined;
            audienceController.saveBody(this.audience);
        }
    }

    public _onSplashFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide('audience-splash-picker-box');
        if (p_assetFile && this.audience) {
            this.audience.splashVideoFileUri = undefined;
            this.audience.splashFileUri = p_assetFile.fileURI;
            audienceController.saveBody(this.audience);
        }
    }

    public _onSplashVideoFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide('audience-splash-video-picker-box');
        if (p_assetFile && this.audience) {
            this.audience.splashFileUri = undefined;
            this.audience.splashVideoFileUri = p_assetFile.fileURI;
            audienceController.saveBody(this.audience);
        }
    }

    // video

    public openVideoRecordBox() {
        this.$bvModal.show('portal-video-record-box');
    }

    public removeVideo() {
        if (this.audience) {
            audienceController.removeVideoMessage(this.audience);
        }
    }

    // portal settings


    get audienceDisplayName(): string {
        return this.audience?.displayName ?? '';
    }

    private getAudiencePortalLink(preview: boolean): string {
        if (this.audience) {
            let link: string;
            if (this.audience.portalShortLinkID.length === 0 || preview) {
                link = `${AppModel.getInstance().global.storageUrl}/portal/index.html?apiUrl=${AppUserModel.getInstance().project.apiUrl}&portalToken=${this.audience.portalToken}`;
            } else {
                link = `${AppUserModel.getInstance().project.apiUrl}/sl/${this.audience.portalShortLinkID}`;
            }
            if (preview) {
                link += '&isPreview=1';
            }
            return link;
        }
        return '';
    }

    private _onPortalBtnClick() {
        if (this.audience) {
            this.$bvModal.show('portal-preview-visitor-page-box');
        }
    }

    private _onCopyLinkBtnClick(p_e: Event) {
        // @ts-ignore
        this.$refs.portalLink.select();
        try {
            document.execCommand('copy');
            this.currentCopyToClipboardState = CopyToClipboardState.SUCCESS;
        } catch (err) {
            this.currentCopyToClipboardState = CopyToClipboardState.ERROR;
        }
        // @ts-ignore
        this.$refs.portalLink.blur();
    }

    private _onNotifyPortalVisitChange() {
        if (this.audience) {
            audienceController.updateConfig(this.audience);
        }
    }

    private _createValidationAudience(audience: AudienceModel): void {
        if (this.audience) {
            this.validationAudience = new AudienceModel();
            this.validationAudience.portalSecret = audience.portalSecret;
        }
    }

    @Watch('audience', {immediate: true, deep: false})
    private _onActiveAudienceChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
        if (newAudience) {
            this._createValidationAudience(newAudience);
        }
    }

    @Watch('audience.portalAuthType', {immediate: true, deep: false})
    private _onActiveAudiencePortalTypeChange(newAudience: PortalAuthType | null, oldAudience: PortalAuthType | null) {
        if (newAudience) {
            this._createValidationAudience(this.audience!);
        }
    }

    @Watch('bodyLoaded', {immediate: true, deep: false})
    private _onBodyLoadedChange(newBodyLoaded: boolean, oldBodyLoaded: boolean) {
        if (newBodyLoaded) {
            this._createValidationAudience(this.audience!);
        }
    }

    private _calculateInputState(inputValidator: any): any {
        return inputValidator.$invalid ? false : null;
    }

    private _onFormValidationInput(inputValidator: any, value: any): any {
        if (!inputValidator.$invalid && this.audience) {
            value = inputValidator.$model;
        }
        return value;
    }
}
</script>
