<template>
  <transition name="fade" mode="out-in">
    <div class="w-100 h-100">

      <transition name="fade" mode="out-in">
        <iframe
          v-if="!isLoading && internalIframeSrc"
          ref="pdfIframe"
          :src="`/pdf-viewer/#/?blobUrl=${internalBlobUrl}&engineMode=${engMode}&scrollMode=${initialScrollMode}&scale=${initialScale}`"
        />
        <div v-else-if="isLoading && !internalIframeSrc && !errorMessage" class="h-100 w-100 d-flex justify-content-center align-items-center">
          <b-spinner />
        </div>
      </transition>

      <transition name="fade">
        <div v-if="errorMessage" class="pdf-player__error empty-message">
          {{ errorMessage }}
        </div>
      </transition>

    </div>

  </transition>
</template>

<script lang="ts">
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {EngineMode} from '@/presentation/player/_model/player.constants';
import {IAudienceSessionDto} from '@/audience/session/_model/audience_session.dto';
import audienceSessionController from '@/audience/session/_controller/AudienceSessionController';
import fileManager from '@/_controller/FileManager';
import apiManager, {ApiRequestType, ApiResponse} from '@/_controller/ApiManager';
import {IContentFileBodyDto} from '@/content_file/_model/content_file.dto';
import NetworkManager from '@/_controller/NetworkManager';

@Component({})
export default class PdfPlayer extends Vue {
  @Ref('pdfIframe') public readonly pdfIframe!: HTMLIFrameElement;

  @Prop({ required: false, default: '' }) public src!: string;
  @Prop({ required: true }) public readonly engMode!: EngineMode;
  @Prop({ required: true }) public readonly sessionIdentifier!: string;
  @Prop({ required: false, default: 'Vertical' }) public readonly initialScrollMode!: string;
  @Prop({ required: false, default: 'pageFit' }) public readonly initialScale!: string;

  private readonly networkManager: NetworkManager = NetworkManager.getInstance();

  public isLoading: boolean = false;
  public errorMessage: string = '';

  public internalIframeSrc: string = '';
  public internalBlobUrl: string = '';
  public origin: string = '';

  // page number - number of seconds
  public trackedPages: Record<string, number> = {};

  async mounted() {
    this.isLoading = true;

    // listen to post message 'page change'
    window.addEventListener('message', this.handlePdfViewerMessages);

    if (this.engMode === EngineMode.ANALYTICS) {
      await this._initializeAnalytics();
    } else {
      this.internalIframeSrc = this.src;
    }

    // if (this.isOnline) {
    await this._setPDFInIframe();
    // }

    this.isLoading = false;
  }

  destroyed() {
    window.removeEventListener('message', this.handlePdfViewerMessages);
  }

  public get isOnline() {
    return this.networkManager.online;
  }

  private async _fetchPDFAsBlob() {
    const response = await fetch(this.internalIframeSrc);
    const pdfArrayBuffer = await response.arrayBuffer();
    return new Blob([pdfArrayBuffer], { type: 'application/pdf' });
  }

  private async _setPDFInIframe() {
    const pdfBlob = await this._fetchPDFAsBlob();
    this.internalBlobUrl = URL.createObjectURL(pdfBlob);
  }

  public handlePdfViewerMessages(event: MessageEvent) {
    if (event?.data && event.data.type === 'page-viewed') {
      this.$emit('pageViewed', event.data.page, event.data.time);
    }

    if (event?.data && event.data.type === 'pdfLoaded' && this.engMode === EngineMode.ANALYTICS) {
      this._transferAnalytics();
    }
  }

  public handleOfflinePageViewed(page: number, time: number) {
    this.$emit('pageViewed', page, time);
  }

  private async _initializeAnalytics() {
    const session: IAudienceSessionDto | null = await audienceSessionController.getAudienceSession(this.sessionIdentifier);

    if (session && session.sessions && session.sessions.length > 0) {
      const apiResponse: ApiResponse<IContentFileBodyDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `client-api/content-files/${session.subjectIdentifier}`);
      if (apiResponse.hasSucceeded && apiResponse.result) {
        this.internalIframeSrc = fileManager.getFileUrl(apiResponse.result.fileUri);

        for (let i = 0; i < session.sessions.length; i += 1) {
          const pageSession = session.sessions[i];

          if (!pageSession.subjectIdentifier) {
            continue;
          }

          this.trackedPages[pageSession.subjectIdentifier] = pageSession.length || 0;
        }
      }
    } else {
      this.isLoading = false;
      this.errorMessage = this.$t('AudienceTrackNoAnalyticsAvailable').toString();
    }
  }

  private _transferAnalytics() {
    if (Object.keys(this.trackedPages).length > 0) {
      if (!this.pdfIframe.contentWindow) {
        console.error('No content window found for PDF iframe. Analytics will not be shared.');
      }

      this.pdfIframe.contentWindow?.postMessage({
        type: 'analyticsLoaded',
        trackedPages: this.trackedPages
      }, '*');
    }
  }
}
</script>
