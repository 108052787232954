import i18n from '@/__plugins/i18n';
import {TranslationStatus} from "@/_model/app.constants";

export class LanguageManager
{


    // ---------------------------------
    // Public Static Properties
    // ---------------------------------



    //---------------------------------
    // Public Properties
    //---------------------------------


    public availableLangCodes:string[] = ["en"];

    //---------------------------------
    // Private Properties
    //---------------------------------



    //---------------------------------
    // getter setters
    //---------------------------------



    //---------------------------------
    // Private Methods
    //---------------------------------



    //---------------------------------
    // Public Methods
    //---------------------------------

    public getTranslationForValue<T>(p_mlValue:IMultiLangValue<T>, p_preferredLangCode:string | null = null):T
    {
        if (!p_preferredLangCode)
        {
            p_preferredLangCode = this.availableLangCodes[0];
        }

        //the preferred language is present
        if (p_mlValue[p_preferredLangCode] !== undefined)
        {
            // console.log("preferred found", p_preferredLangCode);
            return p_mlValue[p_preferredLangCode];
        }

        //fallback to "en" if present
        if (p_mlValue["en"] !== undefined)
        {
            return p_mlValue["en"];
        }


        //fallback to existing languages, taking priority off the languages into account
        for (let i = 0; i < this.availableLangCodes.length; i++)
        {
            if (p_mlValue[this.availableLangCodes[i]] !== undefined)
            {
                // console.log("available found", this.availableLangCodes[i] );
                return p_mlValue[this.availableLangCodes[i]];
            }
        }

        //if still no value is found, return the first found in the mlValue object
        for (let langCode in p_mlValue)
        {
            if (p_mlValue.hasOwnProperty(langCode))
            {
                // console.log("default found", langCode);
                return p_mlValue[langCode];
            }
        }
        return p_mlValue[p_preferredLangCode];
    }

    public getChosenTranslationForValue<T>(p_mlValue:IMultiLangValue<T>, p_preferredLangCode:string | null = null):IMultiLangChosenValue<T>
    {
        if (!p_preferredLangCode)
        {
            p_preferredLangCode = this.availableLangCodes[0];
        }

        //the preferred language is present
        if (p_mlValue[p_preferredLangCode] !== undefined)
        {
            // console.log("preferred found", p_preferredLangCode);
            return {translation: p_mlValue[p_preferredLangCode], langCode: p_preferredLangCode};
        }

        //fallback to "en" if present
        if (p_mlValue["en"] !== undefined)
        {
            return {translation: p_mlValue["en"], langCode: "en"};
        }

        //fallback to existing languages, taking priority off the languages into account
        for (let i = 0; i < this.availableLangCodes.length; i++)
        {
            if (p_mlValue[this.availableLangCodes[i]] !== undefined)
            {
                // console.log("available found", this.availableLangCodes[i] );

                return {translation: p_mlValue[this.availableLangCodes[i]], langCode: this.availableLangCodes[i]};

            }
        }

        //if still no value is found, return the first found in the mlValue object
        for (let langCode in p_mlValue)
        {
            if (p_mlValue.hasOwnProperty(langCode))
            {
                // console.log("default found", langCode);
                return {translation: p_mlValue[langCode], langCode: langCode};
            }
        }
        return {translation: p_mlValue[p_preferredLangCode], langCode: p_preferredLangCode};
    }

    public static renameAsCopy(p_mlValue:IMultiLangValue<string>)
    {
        for (let langCode in p_mlValue)
        {
            if (p_mlValue.hasOwnProperty(langCode))
            {
                const name:string = p_mlValue[langCode];
                p_mlValue[langCode] = i18n.t('LanguageManagerVersion', [name]) as string;
            }
        }
    }

    public static getUniqueValuesFromMultiLangValue<T>(p_mlValue:IMultiLangValue<T>):T[]
    {
        const valueList:T[] = [];
        for (let langCode in p_mlValue)
        {
            if (p_mlValue.hasOwnProperty(langCode))
            {
                if (valueList.indexOf(p_mlValue[langCode]) === -1)
                {
                    valueList.push(p_mlValue[langCode]);
                }

            }
        }
        return valueList;
    }


    public convertMultiLangStringToTrans(p_mlValue:IMultiLangString):IMultiLangTrans
    {
        const multiLangTrans:IMultiLangTrans = {};

        for (let i = 0; i < this.availableLangCodes.length; i++)
        {
            const langCode = this.availableLangCodes[i];
            if (p_mlValue.hasOwnProperty(langCode))
            {
                multiLangTrans[langCode] = {
                    text: p_mlValue[langCode], hasChanges: false, translationStatus: TranslationStatus.IDLE
                };
            }
            else
            {
                multiLangTrans[langCode] = {text: "", hasChanges: false, translationStatus: TranslationStatus.IDLE};
            }
        }
        return multiLangTrans;
    }

}

export default new LanguageManager();



export type IMultiLangString = { [index:string]:string };

export type IMultiLangValue<T> = { [index:string]:T };

export type IMultiLangChosenValue<T> = { translation:T, langCode:string };

export type IMultiLangTrans = { [index:string]:IMultiLangTransValue };

export type IMultiLangTransValue = { text:string, translationStatus:TranslationStatus, hasChanges:boolean } ;
