<template>
  <div class="box box--full-height list-overview share-overview">

    <h4 class="mt-1 mb-3 share-overview__title" v-html="$t('DashboardSharedItemsTitle', [audienceDisplayName])"></h4>

    <div class="list-overview-items" v-if="audienceContentShares.length > 0">
      <ShareOverviewItemRenderer
          v-for="(share, index) in audienceContentShares"
          :share="share"
          :key="index"
          v-on:onClick="_onShareClick"
          v-on:onMobileClick="_onShareMobileClick"
          :selState="selState"></ShareOverviewItemRenderer>
    </div>

    <div class="empty-message text-body" v-if="audienceContentShares.length === 0">
      {{ $t('NoItemsShared') }}
    </div>

    <b-modal id="preview-visitor-page-box" size="xl"
             :title="$t('DashboardSharedViewVisitorPageButtonText', [audienceDisplayName]) " hide-footer>
      <PreviewVisitorPageBox v-if="audience"
                             :portalLink="getAudiencePortalLink(true)"
      ></PreviewVisitorPageBox>
    </b-modal>

    <b-modal id="share-detail-box" :title="$t('ShareDetailBoxTitle')" hide-footer>
      <ShareDetail :selState="selState"></ShareDetail>
    </b-modal>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import SelectionState from "@/__libs/_model/SelectionState";
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import {IAudienceFileDto} from "@/audience/_model/audience.dto";
import PreviewVisitorPageBox from "@/audience/_view/PreviewVisitorPageBox.vue";
import AppModel from "@/_model/AppModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import AudienceItemRenderer from "@/audience/_view/AudienceItemRenderer.vue";
import ShareOverviewItemRenderer from "@/audience/share/_view/ShareOverviewItemRenderer.vue";
import audienceController from "@/audience/_controller/AudienceController";
import CreateAudienceBox from "@/audience/_view/CreateAudienceBox.vue";
import ShareDetail from "@/audience/share/_view/ShareDetail.vue";
import languageManager from "@/__libs/language_manager/LanguageManager";
import FileUtil from "@/__libs/utility/FileUtil";
import NetworkManager from "@/_controller/NetworkManager";
import {ActivityActionTypeFilter} from "@/audience/track/_model/audience_activity.constants";
import {PortalAuthType} from "@/audience/_model/audience.constants";

enum CopyToClipboardState {
    IDLE,
    SUCCESS,
    ERROR
}

@Component({
    components: {ShareDetail, CreateAudienceBox, AudienceItemRenderer, PreviewVisitorPageBox, ShareOverviewItemRenderer}
})
export default class ShareOverview extends Vue {
    @Prop() private selState!: SelectionState<IEntityAudienceShareOutlineDto | IAudienceFileDto>;
    public networkManager: NetworkManager = NetworkManager.getInstance();

    get online() {
        return this.networkManager.online;
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get audienceDisplayName(): string {
        return this.audience?.displayName ?? '';
    }

    get audienceContentShares(): IEntityAudienceShareOutlineDto[] | IAudienceFileDto[] {
        return this.audience?.contentShares ?? [];
    }

    private getAudiencePortalLink(preview: boolean): string {
        if (this.audience) {
            let link: string;
            if (this.audience.portalShortLinkID.length === 0 || preview) {
                link = `${AppModel.getInstance().global.storageUrl}/portal/index.html?apiUrl=${AppUserModel.getInstance().project.apiUrl}&portalToken=${this.audience.portalToken}`;
            } else {
                link = `${AppUserModel.getInstance().project.apiUrl}/sl/${this.audience.portalShortLinkID}`;
            }
            if (preview) {
                link += '&isPreview=1';
            }
            return link;
        }
        return '';
    }

    private _onShareClick(p_share: IEntityAudienceShareOutlineDto, p_e: Event) {
        this.selState.selected = p_share;
    }

    private _onShareMobileClick(p_share: IEntityAudienceShareOutlineDto, p_e: Event) {
        this.selState.selected = p_share;
        this.$bvModal.show('share-detail-box');
    }

}
</script>
